const predeploymentFlags = [
  {
    label: 'feature_predeployment',
    flags: ['feature_predeployment'],
  },
];

const homeFlags = [
  {
    label: 'fleet',
    flags: ['fleet'],
  },
];

export default {
  predeployment: predeploymentFlags,
  home: homeFlags,
};
